import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from "react";
const PlayTechGameLauncher = forwardRef(
  (
    {
      username,
      password,
      realMode = 1,
      language,
      gameCode,
      clientPlatform,
      systemId,
      casino,
      mobileHub,
    
    },
    ref
  ) => {
    const [isLoadedScript, setIsLoadedScript] = useState(false);
    const [iframeSrc,setIframeSrc] = useState('/loading.html');

    useEffect(() => {
      // Load the external script dynamically
      const script = document.createElement("script");
      script.src = `https://login-ag.${mobileHub}.com/jswrapper/integration.js.php?casino=${casino}`;
      script.type = "text/javascript";
      console.log(script.src);
      script.onload = () => {
        if (window.iapiSetCallout) {
          window.iapiSetCallout("LoginAndGetTempToken", calloutLogin);
          setIsLoadedScript(true);
        }
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [casino, mobileHub]);

    const calloutLogin = (response) => {
      console.log("Response", response);
      if (response.errorCode) {
        if (response.errorText) {
          alert(
            `[${response.errorCode}] ${response.errorText.replace(
              "<br>",
              "\r\n"
            )}`
          );
        } else if (response.playerMessage) {
          alert(
            `[${response.errorCode}] ${response.playerMessage.replace(
              "<br>",
              "\r\n"
            )}`
          );
        } else {
          alert(`[${response.errorCode}] Login Fail.`);
        }
      } else {
        const gameUrl = `https://login-ag.${mobileHub}.com/GameLauncher?gameCodeName=${gameCode}&username=${username}&tempToken=${response.sessionToken.sessionToken}&casino=${casino}&clientPlatform=${clientPlatform}&language=${language}&playMode=${realMode}&deposit=&lobby=true&swipeOff=true`;
        console.log("gameUrlplaytech", gameUrl);
        // Open the game launcher in a new tab
        // window.open(gameUrl, "_blank");
        // window.location.href = gameUrl;
        // if (mytab) {
        //   mytab.location.href = gameUrl;
        // } else {
        //   alert("No tab available");
        // }
         if (iframeSrc) {
          setIframeSrc(gameUrl);
        } else {
          alert("No iframe");
        }
      }
    };

    const login = () => {
      if (isLoadedScript) {
        if (window.iapiLoginAndGetTempToken) {
          console.log("Launching game...", {
            username,
            password,
            realMode,
            language,
            systemId,
          });
          window.iapiLoginAndGetTempToken(
            username,
            password,
            realMode,
            language,
            systemId
          );
        } else {
          alert("Integration script not loaded correctly.");
        }
      } else {
        console.log("isLoadedScript false");
      }
    };

    useEffect(() => {
      if (isLoadedScript) {
        login();
      }
    }, [isLoadedScript]);

    useImperativeHandle(ref, () => ({
      login,
    }));

    return (
      <div>
        <button onClick={login} style={{ display: "none" }}>
          Open Game
        </button>
        <iframe
          src={iframeSrc}
          style={{ height: '100vh', width: '100%', border: 'none' }}
          scrolling="yes"
          noresize="noresize"
          title="Game Frame"
        /> 
      </div>
    );
  }
);

export default PlayTechGameLauncher;
