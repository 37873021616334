import React, { useState, useEffect, useReducer } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { BsEnvelopeOpen, BsEnvelope } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useMiddletier } from "../../../../common/middletier";
// import { useStoreState } from '../../../../common/storeContext';
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../../../common/storeContext'
import { useNavigate } from "react-router-dom";
import Loading from '../../../../components/Loading';




const NOTIFICATION_ACTION = {
    SET_MESSAGES: 'SET_MESSAGES',
    SET_SELECTED_MESSAGES: 'SET_SELECTED_MESSAGE',
    SET_REFRESH: 'SET_REFRESH',

}

const initialNotificationData = {
    messages: [],
    selectedMessages: [],
    refresh: false
}

const notificationReducer = (state, action) => {
    switch (action.type) {

        case NOTIFICATION_ACTION.SET_MESSAGES: {
            return { ...state, messages: action.payload };
        }

        case NOTIFICATION_ACTION.SET_SELECTED_MESSAGES: {
            return { ...state, selectedMessages: action.payload };
        }

        case NOTIFICATION_ACTION.SET_REFRESH: {
            return { ...state, refresh: action.payload ?? false };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}


const Notification = (props) => {
    const appDispatch = useStoreDispatch()
    const appState = useStoreState()
    const { query, mutation } = useMiddletier()
    const [read, setRead] = useState(false);
    const [notificationState, notificationDispatch] = useReducer(notificationReducer, initialNotificationData)
    const { t } = useTranslation();
    // const detailsRef = useRef(null);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })


    const handleAllSelect = () => {
        if (notificationState.selectedMessages?.length === notificationState.messages?.length) {
            notificationDispatch({ type: NOTIFICATION_ACTION.SET_SELECTED_MESSAGES, payload: [] })
        } else {
            notificationDispatch({ type: NOTIFICATION_ACTION.SET_SELECTED_MESSAGES, payload: notificationState.messages.map((item) => item.id) })
        }
    };

    const handleItemSelect = (id) => {
        if (notificationState.selectedMessages.includes(id)) {
            notificationDispatch({ type: NOTIFICATION_ACTION.SET_SELECTED_MESSAGES, payload: notificationState.selectedMessages.filter((itemId) => itemId !== id) })
        } else {
            notificationDispatch({ type: NOTIFICATION_ACTION.SET_SELECTED_MESSAGES, payload: [...notificationState.selectedMessages, id] })
        }
    };

    // CHANGE INBOX STATUS
    const onClickStatus = (status) => {
        const method = "changeInboxStatusByMember"
        const _mutations = notificationState.selectedMessages?.reduce((result, selection) => {
            const selectedMessage = notificationState.messages?.find((item) => item.id === selection)
            if (selectedMessage) {
                result.push({
                    index: `${method}_${result.length}`,
                    method,
                    params: [
                        { code: 'id', graphqlType: 'String', required: true, value: selectedMessage?.id },
                        { code: 'status', graphqlType: 'Boolean', required: true, value: status ? false : true },
                    ]
                })
            }
            return result
        }, [])


        mutation([..._mutations])
            .then(({ data }) => {
                notificationDispatch({ type: NOTIFICATION_ACTION.SET_REFRESH, payload: !notificationState.refresh })
                setRead(!read)
            }).catch((error) => {
                console.error(error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }

            })
    };

    // DELETE INBOX
    const onClickDelete = () => {
        const method = "deleteInboxByMember"
        // const _mutations = notificationState.selectedMessages?.reduce((result, selection) => {
        //     const item = notificationState.messages?.find((item) => item.id === selection)
        //     if (item) {
        //         result.push({
        //             index: `${method}_${result.length}`,
        //             method,
        //             params: [
        //                 { code: 'id', graphqlType: 'String', required: true, value: item['id'] }
        //             ]
        //         })
        //     }
        //     return result
        // }, [])
        // mutation([..._mutations])
        const selectedDeleteMessages = [];

        notificationState.selectedMessages?.forEach((selection) => {
            const item = notificationState.messages?.find((item) => item.id === selection);
            if (item) {
                selectedDeleteMessages.push(item['id']);
            }
        });

        mutation([{
            method,
            params: [
                { code: 'id', graphqlType: 'JSON', required: true, value: selectedDeleteMessages }
            ],
            attributes: []
        }])
            .then(({ data }) => {
                if (data[method]) {
                    notificationDispatch({ type: NOTIFICATION_ACTION.SET_REFRESH, payload: !notificationState.refresh })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Failed to delete the inbox.', typeAlert: 'error' }
                    });
                }
            }).catch((error) => {
                console.error(error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }

            })

    };

    // CLICK INBOX AND READ 
    const onToggleRead = (event, messageId) => {
        const selectedMessage = notificationState.messages.find((message) => message.id === messageId)
        if (selectedMessage.status === 'UNREAD') {

            const method = 'changeInboxStatusByMember'

            mutation([{
                method,
                params: [
                    { code: 'id', graphqlType: 'String', required: true, value: selectedMessage?.id },
                    { code: 'status', graphqlType: 'Boolean', required: true, value: true },
                ],
                attributes: []

            }])
                .then(({ data }) => {
                    notificationDispatch({ type: NOTIFICATION_ACTION.SET_REFRESH, payload: !notificationState.refresh })
                })
                .catch((error) => {
                    console.log('error: ', error)
                    if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                    }
                })
        }

    }

    // GET INBOX
    useEffect(() => {
setLoading(true)
        const method = 'getInboxes'


        if (appState?.user?.username) {
            query({
                method,
                params: [
                    { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { recipient: appState?.user?.username }, order: [['created_at', 'DESC']] } }
                ],
                attributes: ['id', 'recipient', 'subject', 'message', 'update_ctr', 'status', 'created_at']

            }).then(({ data }) => {
                setLoading(false)

                notificationDispatch({ type: NOTIFICATION_ACTION.SET_MESSAGES, payload: data[method] })

                const getUnreadNotiCount = data[method].filter(item => item.status === 'UNREAD').length;
                appDispatch({ type: APP_STORE_ACTION.SET_UNREAD_NOTI, payload: getUnreadNotiCount })

            }).catch((error) => {
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })

        }



    }, [appState.user, query, notificationState.refresh, appDispatch, navigate]);

    return (
        <>
            <div className="notification_body">
                {isDesktopOrLaptop ? <div className="font_h2 color_primary wallet_container_title">{t('notification')}</div> :
                    <Title title={"notification"} id={"notification_title"} desktopToggle={false} mobileToggle={true} />}
                <div className={`${isDesktopOrLaptop && 'wallet_container_wrap'}`}>
                    <div className="color_secondary myAccount_notification_header">
                        <div className="myAccount_notification_selectAll">
                            <input
                                type="checkbox"
                                checked={notificationState.selectedMessages?.length === notificationState.messages?.length}
                                onChange={handleAllSelect}
                            />
                            <label>{t('select')}/{t('unselect_for_all')}</label>
                        </div>
                        <div className="font_h2 myAccount_notification_action">
                            <div onClick={() => onClickStatus(read)}>{read ? <BsEnvelope /> : <BsEnvelopeOpen />}</div>
                            <FaTrashAlt className="ms-3" onClick={() => onClickDelete()} />
                        </div>

                    </div>
                    <div className="myAccount_notification_title">
                        {notificationState.messages.map(function (item, index) {
                            return (
                                <details key={item.id} onClick={(event) => onToggleRead(event, item.id)} >
                                    <summary className="myAccount_notification_title_row" >
                                        <input
                                            type="checkbox"
                                            checked={notificationState.selectedMessages.includes(item.id)}
                                            onChange={() => handleItemSelect(item.id)}
                                        />
                                        <div className={`myAccount_notification_titleContent ${item.status === 'UNREAD' && ('unread')}`}>
                                            <p>{item.subject}</p>
                                            <p>{new Date(item.created_at).toLocaleDateString()}</p>
                                        </div>
                                    </summary>
                                    <div className="myAccount_notification_content" dangerouslySetInnerHTML={{ __html: item.message }}></div>
                                </details>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
            {isLoading && (<Loading />)}

        </>

    );




};






export default Notification;
