import React, { useReducer, useContext, useEffect, createContext } from 'react'
// import * as Utils from './utils'

const APP_STORE_ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_ONLINE: 'SET_ONLINE',
  SHOW_ALERT: 'SHOW_ALERT',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  SET_LOGIN_WITH_TOKEN: 'SET_LOGIN_WITH_TOKEN',
  SET_SITE_SETTINGS: 'SET_SITE_SETTINGS',
  RESTORE: 'RESTORE',
  AUTO_TRANSFER: 'AUTO_TRANSFER',
  REFRESH_WALLET_AMOUNT: 'REFRESH_WALLET_AMOUNT',
  REFRESH_GET_GAME_WALLET: 'REFRESH_GET_GAME_WALLET',
  SET_GAME_TYPE: 'SET_GAME_TYPE',
  SET_CURRENCY_FORMAT: 'SET_CURRENCY_FORMAT',
  SET_UNREAD_NOTI: 'SET_UNREAD_NOTI',
  SET_COUPON_ACTIVE_COUNT: 'SET_COUPON_ACTIVE_COUNT',
  SET_COUPON_AMOUNT: 'SET_COUPON_AMOUNT',
  SET_PIXEL_ID: 'SET_PIXEL_ID',
  SET_PIXEL_IDS:'SET_PIXEL_IDS',
  SET_FRIEND_REQUEST_NOTI:'SET_FRIEND_REQUEST_NOTI',
  SET_LOADING:'SET_LOADING'
}

const StoreStateContext = createContext()
const StoreDispatchContext = createContext()

const initialState = {
  loggedIn: false,
  loginWithToken: true,
  online: true,
  schema: [],
  alert: {
    open: false,
    title: '',
    description: '',
    forwardUrl: '',
    typeAlert: ''
  },
  user: {
  },
  menu: [],
  footer: [],
  gameProvidersByType: {
    // "SL":["GP","PP","JL","HB","PNG","NT"],
    // "LC":["AB","AG","BG","DG"],
    // "FI":["JL","SG","YGR"],
    // "LO":["QQK"],
    // "SP":["CMD","IBC"]
  },
  gameProviders: {
  },
  gameTypes: [],
  currency: '',
  cancelPromotion: false,
  registration: {},
  socialMedia: [],
  gameWallet: [],
  friendRequestCount:0,
  unreadNotiCount: 0,
  couponActiveCount: 0,
  currencyFormat: {
    conversion_factor: 1,
    locale: 'en-US',
    prefix: '',
    postfix: 'MYR',
    min_deposit_amount: 0,
    max_deposit_amount: 0,
    default_deposit_amounts: [],
    min_transfer_amount: 0,
    max_transfer_amount: 0,
    decimal_point: 0
  },
  timezone: 'Asia/Kuala_Lumpur',
  enableMall: false,
  mallUrl: '',
  enableAffiliate: false,
  affiliateUrl: '',
  couponAmount: 0,
  pixel_id: '',
  pixelIDs:[],
  enableLoyalty: false,
  loyaltyDecimalPoint: 0,
  loading:false
};

function storeReducer(state, action) {
  switch (action.type) {
    case APP_STORE_ACTION.LOGIN: {
      const { user } = action.payload
      return { ...state, loginWithToken: true, loggedIn: true, user };
    }

    // case APP_STORE_ACTION.RESTORE: {
    //   return { ...state, user: { ...state.user, wallet_amount: action.payload } };
    // }

    case APP_STORE_ACTION.SET_UNREAD_NOTI: {
      return { ...state, unreadNotiCount: action.payload };
    }

    case APP_STORE_ACTION.SET_FRIEND_REQUEST_NOTI: {
      return { ...state, friendRequestCount: action.payload };
    }
    
    case APP_STORE_ACTION.SET_PIXEL_ID: {
      return { ...state, pixelID: action.payload };
    }

    case APP_STORE_ACTION.SET_PIXEL_IDS: {
      return { ...state, pixelIDs: action.payload };
    }

    case APP_STORE_ACTION.SET_COUPON_ACTIVE_COUNT: {
      return { ...state, couponActiveCount: action.payload };
    }

    case APP_STORE_ACTION.SET_CURRENCY_FORMAT: {
      return { ...state, currencyFormat: action.payload };
    }

    case APP_STORE_ACTION.SET_GAME_TYPE: {
      return { ...state, gameTypes: action.payload };
    }

    case APP_STORE_ACTION.REFRESH_GET_GAME_WALLET: {
      return { ...state, gameWallet: action.payload };
    }

    case APP_STORE_ACTION.REFRESH_WALLET_AMOUNT: {
      return { ...state, user: { ...state.user, wallet_amount: action.payload } };
    }

    case APP_STORE_ACTION.SET_COUPON_AMOUNT: {
      return { ...state, couponAmount: action.payload };
    }

    case APP_STORE_ACTION.AUTO_TRANSFER: {
      return { ...state, user: { ...state.user, auto_restore: action.payload } };
    }

    case APP_STORE_ACTION.LOGOUT:
      return { ...state, loginWithToken: false, loggedIn: false, user: null };

    case APP_STORE_ACTION.UNAUTHENTICATED: {
      return { ...state, loginWithToken: false, alert: { type: APP_STORE_ACTION.UNAUTHENTICATED, open: true, title: 'Unauthenticated', description: 'No Access Token, Please Login Again', typeAlert: 'error' } }
    }

    case APP_STORE_ACTION.SET_SITE_SETTINGS: {
      const { menu, registration, dataTypes, currency, footer, timezone, cancelPromotion, enableMall, mallUrl, pageLoadAutoRestore, domain, disablePromotion, transactionRankingData, transactionRankingFrequency,enableFriend,removeExpired,deposit_type_sequence, disableLoyalty,removeUsedExpired,enableLoyaltyDecimalPoint } = action.payload

      const checkLoyalty = disableLoyalty?.includes('WEBSITE')
      const checkLoyaltyDecimalPoint = enableLoyaltyDecimalPoint ? 2 : 0
      
      return {
        ...state, menu, registration, dataTypes, currency, footer, timezone, cancelPromotion, enableMall, mallUrl, pageLoadAutoRestore, domain, disablePromotion, transactionRankingData, transactionRankingFrequency,enableFriend,removeExpired,deposit_type_sequence, enableLoyalty: !checkLoyalty,removeUsedExpired, loyaltyDecimalPoint: checkLoyaltyDecimalPoint
      }
    }

    case APP_STORE_ACTION.SET_ONLINE: {
      return { ...state, online: action.payload }
    }

    case APP_STORE_ACTION.SET_LOADING: {
      return { ...state, loading: action.payload }
    }

    case APP_STORE_ACTION.SHOW_ALERT: {

      if (typeof action.payload === 'boolean' && action.payload === false) {
        return { ...state, alert: { open: false, title: '', description: '', forwardUrl: '', info: '' } }
      }

      if (state.alert.open) {
        return state
      }

      return { ...state, alert: { open: true, title: action.payload.title ?? '', description: action.payload.description ?? '', forwardUrl: action.payload.forwardUrl ?? '', info: action.payload.info ?? '' } }
    }



    case APP_STORE_ACTION.SET_LOGIN_WITH_TOKEN: {
      return { ...state, loginWithToken: action.payload }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(storeReducer, initialState)

  useEffect(() => {
    const controller = new AbortController();

    const _onOnlineStatus = () => {
      dispatch({ type: APP_STORE_ACTION.SET_ONLINE, payload: navigator.onLine })
    }

    dispatch({ type: APP_STORE_ACTION.SET_ONLINE, payload: navigator.onLine })

    window.addEventListener('offline', _onOnlineStatus, { signal: controller.signal });
    window.addEventListener('online', _onOnlineStatus, { signal: controller.signal });

    return () => {
      controller.abort();
    }
  }, [])

  return (
    <StoreStateContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  )
}

function useStoreState() {
  const context = useContext(StoreStateContext)
  if (context === undefined) {
    throw new Error('useStoreState must be used within a StoreProvider')
  }
  return context
}

function useStoreDispatch() {
  const context = useContext(StoreDispatchContext)
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider')
  }
  return context
}

export { StoreProvider, useStoreState, useStoreDispatch, APP_STORE_ACTION }