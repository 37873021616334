import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { GLOBAL_BUCKET } from '../common/constants'
import { AiOutlineArrowRight } from "react-icons/ai"
// import { useForm } from "react-hook-form";
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext'
import { useNavigate } from "react-router-dom";
import { useMiddletier } from "../common/middletier";
import Loading from '../components/Loading';
import Alert from '../components/Alert'
import './TransferBalanceDialog.css';


const TransferBalanceDialog = (props) => {
  const { query, queries, mutation } = useMiddletier();
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const navigate = useNavigate();
  // const { balance, gameProvider, gameUrl } = props
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [alertShow, setAlertShow] = useState({});
  const [isLoading, setLoading] = useState({ show: false, message: null });
  const { walletAmount, gameSelect } = props
  const { t, i18n } = useTranslation();
  const isSafari = () => /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  const [deviceType, setDeviceType] = useState('');
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);

  const onChangeTansferAmount = (newValue) => {
    setTransferAmount(newValue)
  }



  const onClickCloseDialog = () => {
    if (gameSelect.getCurrentGameUrl && isInAppBrowser) {
      // window.open(gameSelect.getCurrentGameUrl, '_blank', 'noreferrer')
      window.location.replace(gameSelect.getCurrentGameUrl);

    } else {
      console.log('error: ', '...')
    }

    props.onHide()
    setShowTransferDialog(false)

  }

  const handleSubmit = () => {

    setLoading({ show: true, message: null });


    // let paramGetGameLinkByMember = [
    //   { code: 'gameProvider', graphqlType: 'String', required: true, value: gameSelect.getCurrentGameProvider },
    //   { code: 'gameType', graphqlType: 'String', required: true, value: gameSelect.getCurrentGameType },
    //   { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
    // ]

    // const mytab = window.open("/loading.html", "_blank");

    // query({
    //   method: 'getGameLinkByMember',
    //   params: paramGetGameLinkByMember,
    //   attributes: []

    // }).then(({ data }) => {

    //   console.log('getGameLinkByMember', data['getGameLinkByMember'])

    //   const gameUrl = data['getGameLinkByMember'].gameLink

    //   mytab.location = gameUrl

    // TRANSFER BALANCE TO GAME PROVIDER
    mutation([{
      method: 'depositGamePrivoderByMember',
      params: [
        { code: 'gameProvider', graphqlType: 'String', required: true, value: gameSelect.getCurrentGameProvider },
        { code: 'currency', graphqlType: 'String', required: false, value: appState.user?.currency ?? appState.currency },
        { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(transferAmount) },
      ],
      attributes: []

    }])
      .then(({ data }) => {

        // SET TRANSFER AMOUNT DEFAULT AGAIN
        setTransferAmount(0)
        setShowTransferDialog(false)

        setLoading({ show: false, message: null });

        setAlertShow({
          show: true, message: 'Transfer Successful', type: 'success', onHide: () => {

            setLoading({ show: true, message: null });

            queries([{
              index: 'getAllGamePrivoderBalanceByMember',
              method: 'getAllGamePrivoderBalanceByMember',
              params: [],
              attributes: []
            },
            {
              index: 'getMember',
              method: 'getMember',
              params: [
                { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { username: appState.user.username } } }
              ],
              attributes: ['wallet_amount']
            },])
              .then(({ data }) => {

                appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['getMember']?.[0]?.wallet_amount })

                if (gameSelect.getCurrentGameUrl && isInAppBrowser) {

                  let paramGetGameLinkByMember = [
                    { code: 'gameProvider', graphqlType: 'String', required: true, value: gameSelect.getCurrentGameProvider },
                    { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
                    { code: 'gameType', graphqlType: 'String', required: true, value: gameSelect.getCurrentGameType },
                    { code: 'game', graphqlType: 'String', required: false, value: gameSelect.getCurrentGameCode },
                    { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
                    { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
                  ]

                  query({
                    method: 'getGameLinkByMember',
                    params: paramGetGameLinkByMember,
                    attributes: []

                  }).then(({ data }) => {
                    const gameUrl = data['getGameLinkByMember'].gameLink
                    setLoading({ show: false, message: null });
                    if (gameUrl !== '') {
                      window.location.replace(gameUrl);
                    }
                  }).catch((error) => {

                    setLoading({ show: false, message: null });
                    setAlertShow({ show: true, message: error.message.toString(), type: 'error' });
                  })



                } else {
                  setLoading({ show: false, message: null });
                }
              }).catch((error) => {
                // console.log('error: ', error)
                setLoading({ show: false, message: null });
                if (error.toString().includes("No Access Token") || error.toString().includes("tokens")) {
                  appDispatch({ type: APP_STORE_ACTION.LOGOUT })
                  setAlertShow({ show: true, message: error.message.toString(), type: 'error' });
                  navigate('/')
                }
              })
          }
        });


      })
      .catch((error) => {
        // console.log('error: ', error)
        setLoading({ show: false, message: null });
        if (error.toString().includes("No Access Token") || error.toString().includes("tokens")) {
          appDispatch({ type: APP_STORE_ACTION.LOGOUT })
          setAlertShow({ show: true, message: error.message.toString(), type: 'error' });
          navigate('/')
        }
      })

    // }).catch((error) => {
    //   mytab.close()
    //   setLoading({ show: false, message: null });
    //   console.log('error: ', error)
    //   setAlertShow({ show: true, message: error.message.toString(), type: 'error' });
    // })

  };

  // const formatAmount = (amount, decimalPoint) => {
  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }
  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');
  
    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }
  
    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });
  
    return formattedAmount;
  };

  const onHideAlert = () => {
    if (alertShow.onHide) {
      alertShow.onHide()
    }

    setAlertShow({ ...alertShow, show: false })
  }

  // CHECK FORM SUBMIT
  useEffect(() => {
    if (parseFloat(transferAmount) > 0) {
      setDisabledSubmit(false)
    }
    else {
      setDisabledSubmit(true)
    }

  }, [transferAmount])

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }

    const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);

    if (isInAppBrowser) {
      setIsInAppBrowser(true)
    } else {
      setIsInAppBrowser(false)
    }

  }, [])


  return (<>
    {walletAmount > 0 ? <>
      <Modal {...props} centered id="depositConfirmDialog" backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>{t('transfer_credit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('transfer_credit_content')}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_button" onClick={onClickCloseDialog} >
            {t('cancel')}
          </button>
          <button className="confirm_button" onClick={() => { props.onHide(); setShowTransferDialog(true) }}>
            {t('confirm')}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showTransferDialog}
        onHide={() => setShowTransferDialog(false)}
        id="transferBalanceModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="transferBalance_body">
          <Modal.Header className="transferBalance_header">
            <Modal.Title className="transferBalance_title">
              <div>
                <label>Main Wallet</label>
                <label>{formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point)}</label>
              </div>
              <AiOutlineArrowRight />
              <img src={`${GLOBAL_BUCKET}/game_provider_logo/${gameSelect.getCurrentGameProvider}.png`} alt={''} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="transferBalance_form" >
              <Form.Group className="form_field" >
                <Form.Label>{t('transfer_amount')}<div className="form_required">*</div></Form.Label>
                <div className="form_field_input">
                  <Form.Control
                    type="text"
                    name="transfer_amount"
                    placeholder={t('transfer_amount')}
                    className="transfer_amount"
                    aria-label="transfer_amount"
                    autoComplete="off"
                    min={0}
                    value={transferAmount ?? 0}
                    onChange={(evt) => { onChangeTansferAmount(evt.target.value) }}
                    onInput={(e) => {
                      let value = appState.currencyFormat.decimal_point === 0
                        ? e.target.value.replace(/[^0-9]/g, '')
                        : e.target.value.replace(/[^0-9.]/g, '');

                      if (value.split('.').length - 1 > 1) {
                        value = value.substring(0, value.lastIndexOf('.'));
                      }

                      const decimal_part = value.split(".")[1];
                      if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                        value = value.slice(0, -1);
                      }
                      e.target.value = value;
                      // e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />

                </div>
              </Form.Group>
              <div className="transferBalance_button">
                <button className="font_button color_button btnCancel transferBalance_btnCancel" onClick={onClickCloseDialog} type="button">{t('cancel')}</button>
                <button className="font_button color_button transferBalance_btnSubmit" onClick={handleSubmit} disabled={disabledSubmit} type="button">{t('submit')}</button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal> </> : <Modal {...props} centered id="depositConfirmDialog" backdrop="static"
        keyboard={false}>
      <Modal.Header>
        <Modal.Title>{t('insufficient_wallet_funds')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('insufficient_wallet_funds_content')}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel_button" onClick={onClickCloseDialog} >
          {t('cancel')}
        </button>
        <button className="confirm_button" onClick={() => { navigate('/wallet/deposit') }}>
          {t('confirm')}
        </button>
      </Modal.Footer>
    </Modal>}
    {/* <Alert show={alertShow.show} onHide={() => setAlertShow({ ...alertShow, show: false })} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
    <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} />
    {isLoading.show && <Loading message={isLoading.message} />}
  </>


  );
}

export default TransferBalanceDialog;
